import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "./Layout";
import StandardBanner from "./StandardHero/StandardHero";
import SubNav from "./SubNav/SubNav";

const AdditionalServicesLayout = ({ children, currentPath, metaData }) => {
  const { banner } = useStaticQuery(
    graphql`
      {
        banner: strapiSolutionsAdditionalServices {
          hero {
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
            title
            showBreadcrumb
          }
        }
      }
    `
  );

  const bannerImage =
    banner.hero.image.localFile.childImageSharp.gatsbyImageData;
  const bannerTitle = banner.hero.title;
  const showBreadcrumb = banner.hero.showBreadcrumb;

  const breadcrumbs = [{ name: "WHAT WE DO" }, { name: "ADDITIONAL SERVICES" }];

  const navArray = [
    {
      name: "Fund structures",
      path: "/solutions/additional-services/fund-structures",
    },
    {
      name: "Independent risk oversight",
      path: "/solutions/additional-services/independent-risk-oversight",
    },
    {
      name: "Client experience",
      path: "/solutions/additional-services/client-experience",
    },
    {
      name: "Money market funds",
      path: "/solutions/additional-services/money-market-funds",
    },
  ];
  return (
    <Layout
      seo={{
        title: metaData?.pageTitle,
        description: metaData?.metadescription,
        image: bannerImage,
      }}
    >
      <StandardBanner
        image={bannerImage}
        title={bannerTitle}
        showBreadcrumb={showBreadcrumb}
        breadcrumbs={breadcrumbs}
      />
      <SubNav navArray={navArray} currentPath={currentPath} />
      {children}
    </Layout>
  );
};

export default AdditionalServicesLayout;
